import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { ContentItemDto } from '../../dtos/content-item/content-item.dto';
import { LocalizedStringDto } from '../../dtos/localized-string.dto';
import { PoiDetailsDto } from '../../dtos/poi/poi-details.dto';
import { CollectionType } from '../../enums/collection-type.enum';
import { PoiDisplayType } from '../../enums/poi-display-type.enum';
import { PoiDisplayService } from '../../services/poi-display.service';

@Component({
  selector: 'app-poi-detail',
  templateUrl: './poi-detail.component.html',
  styleUrls: ['./poi-detail.component.scss']
})
export class PoiDetailComponent implements OnChanges {

  @Input()
  poi! : PoiDetailsDto;

  tabTypes = PoiDisplayType.getAll();
  contentTypes = PoiDisplayType.getContentTypes();

  hasImages: boolean;
  hasDocuments: boolean;
  hasMaps: boolean;

  display: PoiDisplayType = PoiDisplayType.Info; 

  linkText : { [key: string] : string } = {
    "discovery": "SWISSCOVERY",
    "wikipedia": "WIKIPEDIA",
    "hls": "HISTORIC_ENCYCLOPEDIA"
  }

  constructor(private poiDisplayService: PoiDisplayService, 
    private localize: LocalizeRouterService,
    private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.display = PoiDisplayType.Info;
  }
 
  setDisplay(display: PoiDisplayType = PoiDisplayType.Info) {
    this.display = display;
  }

  getItems(display: PoiDisplayType) {
    if (display === PoiDisplayType.Info) {
      return null;
    }
    return this.poi.contentItems.filter(item => item.type === display.key);
  }

  getItemsCount(display: PoiDisplayType): string {
    let number = this.poi.contentItems.filter(item => item.type === display.key).length;
    return number > 0 ? number.toString() : '';
  }

  getPoiHasType(display: PoiDisplayType): boolean {
    if (display === PoiDisplayType.Info) {
      // TODO: Loop over all links and description
    }
    return display === PoiDisplayType.Info || this.poi.contentItems.find(item => item.type === display.key) !== undefined;
  }

  getItemRoute(item: ContentItemDto): string {
    return this.poiRoute + '/CONTENT_ITEM/' + item.id;
  }

  emitShouldCloseDrawer() {
    this.poiDisplayService.shouldCloseDrawer.next(true);
  }

  currentLanguageValueExists(property: LocalizedStringDto): boolean {
    let translated = property[this.translate.currentLang as keyof LocalizedStringDto];
    return !!translated;
  }

  private get poiRoute(): string {
    let route = "";
    if (this.poiDisplayService.selectedCollection) {
      switch (this.poiDisplayService.collectionType) {
        case CollectionType.Route:
          route = "/ROUTES/" + this.poiDisplayService.selectedCollection.id;
          break;
        case CollectionType.Dossier:
          route = "/TOPICS/" + this.poiDisplayService.selectedCollection.id;
          break;
        default:
          // Pass
          break;
      }
    }    
    return route + '/POI/' + this.poi.id;
  }

  get currentLink(): string {
    return location.origin + this.localize.translateRoute(this.poiRoute) as string;
  }
}
