import { LocalizedStringDto } from '../localized-string.dto';
import { LocalizedStringMultipleDto } from '../localized-string-multiple.dto';
import { ReferencePagesDto } from '../reference-pages.dto';
import { CoordinatesDto } from '../coordinates.dto';
import { ContentItemDto } from '../content-item/content-item.dto';
import {
  IsArray, IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested
} from 'class-validator';
import { Type } from 'class-transformer';
import { TrackedUpdate } from '../tracked-update.dto';

export class PoiDetailsDto extends TrackedUpdate {

  @IsNotEmpty()
  id: string;

  @IsOptional() @IsString()
  qId: string | null;

  @IsOptional() @IsNumber()
  objectId: number | null;

  @IsOptional() @IsString()
  nodeId: string | null;

  @IsBoolean()
  migrated: boolean = false;

  @IsBoolean()
  isArea: boolean = false;

  @IsNotEmpty() @ValidateNested()
  name: LocalizedStringDto;

  @IsNotEmpty() @ValidateNested()
  description: LocalizedStringDto;

  @IsNotEmpty() @ValidateNested()
  nameVariants: LocalizedStringMultipleDto;

  @IsNotEmpty() @ValidateNested()
  popupContent: LocalizedStringDto;

  @IsOptional() @IsNumber()
  parentRegion: number | null;

  @IsNotEmpty() @ValidateNested()
  references: ReferencePagesDto;

  @IsNotEmpty() @ValidateNested()
  coordinates: CoordinatesDto;

  @IsOptional() @IsString()
  // TODO Property necessary?
  status: string | null;

  @IsOptional() @IsString()
  thumbnail: string | null;

  @IsBoolean()
  published: boolean = false;

  @IsArray() @ValidateNested({ each: true }) @Type(() => ContentItemDto)
  contentItems: ContentItemDto[];

}
