import { Type } from 'class-transformer';
import { IsArray, IsBoolean, IsIn, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ReferencePagesDto } from '../reference-pages.dto';
import { ReferenceDto } from '../reference.dto';
import { TrackedUpdate } from '../tracked-update.dto';

export class ContentItemDetailsDto extends TrackedUpdate {
  
  @IsNotEmpty()
  id: string;

  @IsOptional() @IsString()
  creator: string | null;

  @IsOptional() @IsString()
  description: string | null;

  @IsOptional() @IsString()
  docId: string | null;

  @IsOptional() @IsString()
  doi: string | null;

  @IsOptional() @IsString()
  nodeId: string | null;

  @IsOptional() @IsString()
  image: string | null;

  @IsOptional() @IsString()
  legacyId: string | null;

  @IsOptional() @IsString()
  misc: string | null;

  @IsBoolean()
  migrated: boolean = false;

  @IsOptional() @IsNumber()
  objectId: number | null;

  @IsOptional() @IsString()
  pageId: string | null;

  @IsOptional() @IsString()
  pubYear: string | null;

  @IsOptional() @IsString()
  pubDate: string | null;

  @IsNotEmpty() @ValidateNested()
  references: ReferencePagesDto;

  @IsOptional() @IsString()
  rights: string | null;

  @IsOptional() @IsString()
  source: string | null;

  @IsOptional() @IsString()
  thumbnail: string | null;

  @IsString() @IsNotEmpty() @IsIn(['document', 'map', 'image'])
  type: string;

  @IsString() @IsNotEmpty()
  title: string;

  @IsArray() @ValidateNested({ each: true }) @Type(() => ReferenceDto)
  referencedInPois: ReferenceDto[];

}
